import { MainWebsiteEvents } from "@glamcorner/gc-types/generated/ipcBusEvents";
import { GcSessionInfo } from "@glamcorner/gc-types/generated/types";
import Bus from "./Bus";

function pluck(data: any, keys: any) {
  return keys.reduce((acc: any, key: any) => ({ [key]: data[key], ...acc }), {});
}

export function isSubscriptionCustomer(customerInfo: any) {
  return customerInfo && customerInfo.customerGroup.includes("subscription");
}

export function getSubscriptionType(customerInfo: any) {
  if (customerInfo && customerInfo.customerGroup && customerInfo.customerGroup.includes("subscription")) {
    return customerInfo.customerGroup.includes("unlimited") ? "unlimited" : "starter";
  } else {
    return "none";
  }
}

interface Options {
  bus?: Bus;
  clock?: any;
  delay?: (milliseconds: number) => Promise<void>;
}

const requestTimeoutMs = 6000;
const delayStepBetweenRetriesMs = 500;

export default class GcSession {
  private bus: Bus;
  private clock: any;
  private delay: (milliseconds: number) => Promise<void>;
  private data?: GcSessionInfo;
  public _fetchSessionPromise: Promise<GcSessionInfo>;

  constructor(bus: Bus, { clock, delay }: Options = {}) {
    this.bus = bus;
    this.clock = clock || { getTime: () => new Date().getTime() };
    this.delay = delay || this._delay.bind(this);
    this._fetchSessionPromise = this.fetchSession();

    this.bus.on(MainWebsiteEvents.GcSessionXhrFetch, {}, () => {
      // TODO need tests that subsequent calls to getSessionValues() use new values.
      this._fetchSessionPromise = this.fetchSession();
    });
  }

  getSessionValues(...keys: any[]) {
    const self = this;
    // only respond after we've fetched session data from server
    return this._fetchSessionPromise.then(() => {
      // then resolve from local cache in case changes have been made since fetching from server
      return pluck(self.data, keys);
    });
  }

  async setSessionValues(data: GcSessionInfo) {
    const self = this;
    // wait for initial session values before setting more
    await self._fetchSessionPromise;

    await fetch("/gcsession", { method: "POST", body: JSON.stringify(data) });

    this.data = { ...this.data, ...data }; // cache locally in case we subsequently fetch before loading new page
  }

  private async fetchSession(): Promise<GcSessionInfo> {
    const getDelayTime = (tryNumber: number) => delayStepBetweenRetriesMs * tryNumber;
    this.data = await this.withRetry(() => this.getGcSession(), getDelayTime);
    this.bus.broadcast(MainWebsiteEvents.GcSessionXhrFinished, this.data);
    return this.data;
  }

  private async withRetry<T>(callback: () => Promise<T>, getDelayTime: (tries: number) => number): Promise<T> {
    let value: T | undefined;
    const tryLimit = 50;
    let tries = 0;
    do {
      try {
        tries += 1;
        value = await callback();
      } catch (error) {
        await this.delay(getDelayTime(tries));
      }
    } while (!value && tries < tryLimit);
    if (!value) {
      throw "Max retry exceeded when getting GC session";
    }
    return value;
  }

  private async _delay(milliseconds: number): Promise<void> {
    return new Promise((res) => setTimeout(res, milliseconds));
  }

  private cacheBuster() {
    return this.clock.getTime();
  }

  private async getGcSession(): Promise<GcSessionInfo> {
    const url = `/gcsession?_=${this.cacheBuster()}`;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), requestTimeoutMs);
    const response = await fetch(url, {
      signal: controller.signal,
    });
    clearTimeout(id);

    return (await response.json()) as GcSessionInfo;
  }
}
