export const BUS_POST_MESSAGE_TYPE = "bus-proxy";

export default class Bus {
  constructor() {
    this._messageLogByEventName = {};
  }

  connectIFrame(iframe) {
    const eventsBeingProxied = [];

    function broadcastToIFrame(eventName, data) {
      iframe.contentWindow.postMessage(
        {
          type: BUS_POST_MESSAGE_TYPE,
          method: "broadcast",
          args: {
            eventName,
            data,
          },
        },
        iframe.contentWindow.location.origin
      );
    }

    window.addEventListener("message", (postMessageEvent) => {
      const messagePath = this.getMessagePath(postMessageEvent);
      const iframeUrl = new URL(iframe.src);

      const isNotMessageFromIFrame = messagePath !== `${iframeUrl.origin}${iframeUrl.pathname}`;
      if (isNotMessageFromIFrame) {
        return;
      }

      if (postMessageEvent.data.type !== BUS_POST_MESSAGE_TYPE) {
        return;
      }

      const { method, args } = postMessageEvent.data;

      switch (method) {
        case "broadcast":
          this.broadcast(args.eventName, args.data);
          break;
        case "on":
          if (eventsBeingProxied.includes(args.eventName)) {
            break;
          } else {
            eventsBeingProxied.push(args.eventName);
          }

          this.on(args.eventName, args.options, (busMessageData) => {
            broadcastToIFrame(args.eventName, busMessageData);
          });
          break;
        case "once":
          this.once(args.eventName, (busMessageData) => {
            broadcastToIFrame(args.eventName, busMessageData);
          });
          break;
        case "ping":
          try {
            iframe.contentWindow.postMessage(
              {
                type: BUS_POST_MESSAGE_TYPE,
                method: "pong",
                args: {},
              },
              // dont know target origin, so use '*'. We were previously setting this to
              // iframe.contentWindow.location.origin, but that is no better than '*',
              // and sometimes calling location.origin was throwing SecurityError
              // (possibly when content of iframe failed to load properly in some way and
              // was showing some browser connection diagnosis page - ie we got a ping from
              // the frame, but then page unloaded before we could respond)
              "*"
            );
          } catch (err) {
            if (err.name === "SecurityError") {
              throw new Error(`${err.name} - ${err.message} - ${iframe.src} - INSIDE PING`);
            } else {
              throw err;
            }
          }

          break;

        default:
          console.log("[Bus] Unknown Bus method: ", method);
      }
    });
  }
  getMessagePath(postMessageEvent) {
    const location = postMessageEvent.source.location;
    return `${location.origin}${location.pathname}`;
  }

  /**
   * @param eventName {string}
   * @param options {object} optional
   * @param callback {Function}
   */
  on(eventName, options, callback) {
    if (arguments.length == 2) {
      return this.on(arguments[0], {}, arguments[1]);
    }
    if (typeof eventName !== "string") {
      throw new TypeError("first argument (event name) must be string");
    }
    if (typeof callback !== "function") {
      throw new TypeError("last argument (callback) must be a function");
    }
    const replay = options.hasOwnProperty("replay") ? options["replay"] : true;
    const subscriber = new Subscriber(callback);
    const messageLog = this._messageLog(eventName);
    messageLog.addSubscriber(subscriber, replay);
    return new Subscription(messageLog, subscriber);
  }

  /**
   * @param eventName {string}
   * @param data {Object}
   */
  broadcast(eventName, data) {
    if (typeof eventName !== "string") {
      throw new TypeError("first argument (event name) must be string");
    }
    data = data || null; // use null, not undefined
    if (this._logOn()) {
      console.log(`bus: ${eventName} %o`, data);
    }
    this._messageLog(eventName).push(data);
  }

  once(eventName, callback) {
    const subscription = this.on(eventName, { replay: false }, (...args) => {
      subscription.off();
      callback(...args);
    });
  }

  log(on) {
    if (on) {
      window["localStorage"] && localStorage.setItem("gc.bus.log", "on");
    } else {
      window["localStorage"] && localStorage.removeItem("gc.bus.log");
    }
  }

  _logOn() {
    const storage = window["localStorage"];
    return storage && !!storage.getItem("gc.bus.log");
  }

  /**
   * @param eventName {string}
   * @return {MessageLog}
   * @private
   */
  _messageLog(eventName) {
    if (!this._messageLogByEventName.hasOwnProperty(eventName)) {
      this._messageLogByEventName[eventName] = new MessageLog();
    }
    return this._messageLogByEventName[eventName];
  }
}

class MessageLog {
  constructor() {
    this._messages = [];
    this._subscribers = [];
  }

  push(data) {
    this._messages.push(data);
    this._distributeMessages();
  }

  addSubscriber(subscriber, replay) {
    this._subscribers.push(subscriber);
    if (!replay) {
      subscriber.nextMessageId = this._messages.length;
    }
    this._distributeMessages();
  }

  removeSubscriber(subscriber) {
    this._subscribers = this._subscribers.filter((s) => s !== subscriber);
  }

  _distributeMessages() {
    this._subscribers.forEach((subscriber) => {
      for (let i = subscriber.nextMessageId; i < this._messages.length; i++) {
        const message = this._messages[i];
        subscriber.nextMessageId = i + 1;
        subscriber.callback(message);
      }
    });
  }
}

class Subscriber {
  constructor(callback) {
    this.callback = callback;
    this.nextMessageId = 0;
  }
}

class Subscription {
  constructor(messageLog, subscriber) {
    this._messageLog = messageLog;
    this._subscriber = subscriber;
  }

  off() {
    this._messageLog.removeSubscriber(this._subscriber);
  }
}

Bus.Subscription = Subscription;
