import "../utils/setWebpackPublicPath";
import { AccountsEvents } from "@glamcorner/gc-types/generated/ipcBusEvents";
import { setOwnerFromLocation, setStorefrontFromLocation } from "@glamcorner/core-cf-lib/lib/client";
import "../core/Analytics";
import initialiseAnalytics from "../core/Analytics2";
import Bus from "../core/Bus";
import GcSession from "../core/GcSession";
import customerSessionManagement from "../all/customerSessionManagement";
import ProfileService from "../common/services/ProfileService";

const bus = new Bus();
const gcSession = new GcSession(bus); // kick off xhr request as early as possible

const profileService = new ProfileService();

// @ts-ignore
// Needed within functions called below. This doesn't actually set window.GlamCornerCore
// as Webpack will take the export statement at the bottom of this file and export as window.GlamCornerCore
window.GlamCornerCore = { bus, gcSession, profileService };

declare global {
  interface Window {
    dataLayer: [];
  }
}
window.dataLayer = window.dataLayer || [];
initialiseAnalytics(window.dataLayer, bus);

customerSessionManagement();

function init() {
  const timer = setInterval(() => {
    // im surprised we have to do this, but it seems we were running before document.body is defined.
    if (window.document.body) {
      clearInterval(timer);
      setStorefrontFromLocation();
      setOwnerFromLocation();
    }
  }, 50);
}

// it's not nice that loading a module causes it to / has side-effects.
// so we might remove this call at some point and only export it.
init();

// Stub for legacy tracking compatibility
// @ts-ignore
window.GC.pageInfo = {
  module: "webflow",
  route: window.location.pathname,
  controller: "webflow",
  action: "view",
};

// Delay loading cart iFrame to reduce time to interactive. Customers are unlikely to interact with
// the cart within the delay.
setTimeout(() => {
  const onDemandNavCartEl = document.querySelector(".js-ondemand-cart-root");
  if (onDemandNavCartEl) {
    import("../all/cartMenu").then((importedModule) => {
      importedModule.initCartMenu(onDemandNavCartEl);
    });
  }
}, 3000);

bus.on(AccountsEvents.CustomerLogin, {}, (data: any) => {
  const accountNavIconRootElement = document.querySelector(".js-account-nav-icon-root");
  if (accountNavIconRootElement) {
    import("../all/accountMenu").then((importedModule) => {
      importedModule.initAccountMenu(accountNavIconRootElement, bus);
    });
  }

  if (data.subscriptionType && data.subscriptionType !== "none") {
    const subscriptionNavBoxEl: HTMLElement | null = document.querySelector(".js-subscription-box-root");
    if (subscriptionNavBoxEl) {
      subscriptionNavBoxEl.style.display = "block";
      import("../all/subscriptionBoxMenu").then((importedModule) => {
        importedModule.initSubscriptionBoxMenu(subscriptionNavBoxEl);
      });
    }
    const mobileApplicationPromptRoot: HTMLElement | null = document.querySelector(".js-mobile-application-prompt-root");
    if (mobileApplicationPromptRoot) {
      mobileApplicationPromptRoot.style.display = "block";
      import("../all/MobileApplicationPromptGeneral/MobileApplicationPromptGeneral").then((importedModule) => {
        importedModule.initMobileApplicationPrompt(mobileApplicationPromptRoot);
      });
    }
  }
});

const carouselQuerySelector = ".js-webflow-carousel";
if (document.querySelectorAll(carouselQuerySelector).length > 0) {
  import("./carousel").then((carouselModule) => {
    carouselModule.loadCarousels(carouselQuerySelector);
  });
}

const landingPageDatePickerContainer = document.querySelectorAll(".js-rental-date-field");
landingPageDatePickerContainer.forEach((container) => {
  import("./datepicker").then((importedModule) => {
    importedModule.initDatePicker(container);
  });
});

export { bus, gcSession, profileService };
