import { ProfileWithSubscription } from "../../types";
import { V1GetProfileResponse, V2GetBuiltBoxThisBillingPeriodResponse } from "../../communicationTypes";

// Caching is implemented here in hopes that it can be called may times from different services
// Without having to wait on the api to return each time
export default class ProfileService {
  private profile: ProfileWithSubscription | undefined;
  private isProfileLoaded: boolean;

  constructor() {
    this.isProfileLoaded = false;
  }

  async getProfile(forceFresh = false): Promise<ProfileWithSubscription | undefined> {
    if (this.isProfileLoaded && !forceFresh) {
      return this.profile;
    }

    const { loggedIn, token } = await GlamCornerCore.gcSession.getSessionValues("loggedIn", "token");

    if (loggedIn) {
      const response = await fetch(`/gc-api/v1/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const body: V1GetProfileResponse = await response.json();
      this.profile = body.body;
    }

    this.isProfileLoaded = true;
    return this.profile;
  }

  async getBuiltBoxThisBillingPeriod(): Promise<boolean> {
    // has feature flag enabled
    if (this.isProfileLoaded && this.profile && this.profile.customerSubscription?.reservedItemsFeatureFlag) {
      const { loggedIn, token } = await GlamCornerCore.gcSession.getSessionValues("loggedIn", "token");
      if (loggedIn) {
        const response = await fetch(`/api/v2/built-box-this-billing-period`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data: V2GetBuiltBoxThisBillingPeriodResponse = await response.json();
        return data.body.builtBoxThisBillingPeriod;
      }
      return false;
    }
    return false;
  }
}
