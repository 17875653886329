// wrapper around GTM 'dataLayer' that allows use of higher-level events
// and ability to consume events outside GTM in future.

import {
  DATE_PICKER_EVENTS,
  POSTCODE_SERVICE_EVENTS,
  PRODUCT_PAGE_EVENTS,
  TOOLTIP_EVENTS,
  BAG_PAGE_EVENTS,
  PRODUCT_CALENDAR_SERVICE_EVENTS,
} from "./constants";
import {
  CheckoutEvents,
  MainWebsiteEvents,
  MyAccountCancelMyOrderEvents,
  MyAccountOrderDetailsEvents,
  MyAccountRefundMyOrderEvents,
  OnDemandEvents,
  SubscriptionEvents,
} from "@glamcorner/gc-types/generated/ipcBusEvents";
import { hostnameToStorefront, getOwnerFromStorefront } from "@glamcorner/core-cf-lib/lib/client";
import { analyticsLog as analyticsLogFactory } from "./analyticsLog";

class Analytics {
  constructor(dataLayer, deferredEvents = []) {
    this.analyticsLogs = analyticsLogFactory();
    this.dataLayer = dataLayer;

    deferredEvents.forEach((event) => {
      this.push(event);
    });
  }

  push(ourEvent) {
    var key = Object.keys(ourEvent)[0];
    var value = ourEvent[key];
    if (key == "push") key = "_push";
    if (this[key]) {
      this[key].call(this, value);
    } else {
      console.error('eh? "' + key + '"');
    }
  }

  pageTypeView() {
    const styleCodesFromDom = (selectorPrefix) => {
      return [].slice
        .call(document.querySelectorAll(((selectorPrefix || "") + " [data-product]").trim()))
        .map((n) => {
          let data;
          try {
            data = JSON.parse(n.getAttribute("data-product"));
          } catch (e) {}
          return data;
        })
        .filter((a) => a)
        .map((p) => p.style);
    };
    let pageType = "unknown";
    let styleCodesSelectorPrefix = null;
    const path = window.location.pathname;
    if (path.match(/^\/$/)) {
      pageType = "home";
    } else if (path.match(/^\/designers\/[^\/]+\/[\w0-9-]+/)) {
      pageType = "product";
    } else if (path.match(/^\/catalogsearch\/result/)) {
      pageType = "search";
    } else if (path.match(/^\/checkout\/cart/)) {
      pageType = "bag";
      styleCodesSelectorPrefix = ".bag-item";
    } else if (path.match(/^\/checkout\/onepage\/success/)) {
      pageType = "success";
    } else if (path.match(/^\/checkout\/onepage/)) {
      pageType = "checkout";
    } else if (document.querySelector("#product-list-wrapper")) {
      pageType = "category";
    }
    const styleCodes = styleCodesFromDom(styleCodesSelectorPrefix);
    const first5StyleCodes = styleCodes.slice(0, 5);
    this._push({
      event: "pageTypeView",
      pageTypeView: {
        path,
        pageType,
        styleCodes,
        first5StyleCodes,
      },
    });
  }

  addToBag() {
    this._attrapp("track", "Add To Bag", {});
  }

  removeFromBag() {
    this._attrapp("track", "Remove From Bag", {});
  }

  orderComplete(order) {
    var isNewCustomer = this._getData("isNewCustomer");
    if (isNewCustomer !== null) {
      this._push({
        event: "orderNewOrReturningCustomer",
        newOrReturning: isNewCustomer ? "new" : "returning",
      });
    }
    this._attrapp("track", "Order Complete", { revenue: order.value, isNewCustomer: isNewCustomer });
  }

  heartPressed() {
    this._push({
      event: "heartPressed",
    });
  }

  heartPressedSet() {
    this._push({
      event: "heartPressedSet",
    });
  }

  heartPressedUnset() {
    this._push({
      event: "heartPressedUnset",
    });
  }

  favourited() {
    this._push({
      event: "favourited",
    });
  }

  unfavourited() {
    this._push({
      event: "unfavourited",
    });
  }

  redirectedToSignInForFavourite() {
    this._push({
      event: "redirectedToSignInForFavourite",
    });
  }

  attemptedSignUp() {
    this._push({
      event: "attemptedSignUp",
    });
  }

  customerLogin(data) {
    this._push({
      event: "customerLogin",
      data,
    });
  }

  customerLogout() {
    this._push({
      event: "customerLogout",
    });
  }

  attemptedForgotPassword() {
    this._push({
      event: "attemptedForgotPassword",
    });
  }

  identify(customerId, customerProperties) {
    this._attrapp("identify", customerId, customerProperties || {});
  }

  joinNowPressed() {
    this._push({
      event: "joinNowPressed",
    });
  }

  rafEmailSharePressed() {
    this._push({
      event: "rafEmailSharePressed",
    });
  }

  rafFacebookSharePressed() {
    this._push({
      event: "rafFacebookSharePressed",
    });
  }

  rafTwitterSharePressed() {
    this._push({
      event: "rafTwitterSharePressed",
    });
  }

  rafSmsSharePressed() {
    this._push({
      event: "rafSmsSharePressed",
    });
  }

  rentalProductsNoLongerAvailable(products) {
    this._push({
      event: "rental-products-no-longer-available",
      data: products,
    });
  }

  _push(event) {
    if (this._isLogOn()) {
      this._logEvent(event);
    }
    this._pushGtm(event);
    this._sendToMessageLog(event);
  }

  _pushGtm(event) {
    const storefront = hostnameToStorefront();
    const owner = getOwnerFromStorefront(storefront);
    if (owner !== "davidjones") {
      this.dataLayer.push(event);
    }
  }

  _sendToMessageLog(event) {
    this.analyticsLogs(event);
  }

  // simple delayed callback for now.
  // in future may pass to gtm, but would have to have fallback timer and also ensure callback only fired once
  _callback(callback) {
    if (callback) {
      setTimeout(callback, 500);
    }
  }

  _getData(name) {
    const value = this.dataLayer.find(function (data) {
      return data[name];
    });
    if (value) {
      return value[name];
    } else {
      return null;
    }
  }

  _attrapp() {
    if (window["Attribution"]) {
      var args = [].slice.apply(arguments, []);
      var method = args.shift();
      Attribution[method].apply(Attribution, args);
    }
  }

  _isLogOn() {
    return window.localStorage && window.localStorage.getItem("analytics.event-log-on");
  }

  logging(on) {
    if (on) {
      window.localStorage && window.localStorage.setItem("analytics.event-log-on", "true");
    } else {
      window.localStorage && window.localStorage.removeItem("analytics.event-log-on");
      this.clearLog();
    }
  }

  _logEvent(event) {
    var log = this.getLog();
    log.push(event);
    localStorage && localStorage.setItem("analytics.event-log", JSON.stringify(log));
  }

  getLog() {
    var logStr = localStorage && localStorage.getItem("analytics.event-log");
    var log = logStr ? JSON.parse(logStr) : [];
    return log;
  }

  clearLog() {
    localStorage && localStorage.removeItem("analytics.event-log");
  }
}

window["GC"] = window["GC"] || {};
window.dataLayer = window.dataLayer || [];
GC.analytics = new Analytics(window.dataLayer, GC.analytics);

window.addEventListener("load", () => {
  Array.from(document.querySelectorAll("form")).forEach((form) => {
    form.addEventListener("submit", () => {
      const emailField = form.querySelector("[type=email]");

      if (emailField) {
        const email = emailField.value;
        if (email) {
          try {
            GC.analytics.identify(email);
          } catch (e) {
            console.log(e);
          }
        }
      }
    });
  });

  (function (bus) {
    function getPageAction() {
      const page = GC.pageInfo;
      if (page) {
        return page.module + "." + page.controller + "." + page.action;
      } else {
        return "non-magento-page";
      }
    }

    function pushEvent(eventName, data) {
      GC.analytics._push({
        event: eventName,
        pageAction: getPageAction(),
        data: data,
        pathname: window.location.pathname,
      });
    }

    function forwardBusEventsToAnalytics(eventNames) {
      for (var i = 0; i < eventNames.length; i++) {
        (function (eventName) {
          bus.on(eventName, function (data) {
            pushEvent(eventName, data);
          });
        })(eventNames[i]);
      }
    }

    bus.on("test-features", function (data) {
      // formatted for ease of use within mixpanel
      GC.analytics._push({
        event: "test-features",
        testGroupHash: data.testGroupHash,
        featuresOn: data.testFeatures.filter((m) => m.onOff).map((m) => m.feature),
        featuresOff: data.testFeatures.filter((m) => !m.onOff).map((m) => m.feature),
      });
    });

    const busEvents = [
      DATE_PICKER_EVENTS.DATE_SELECTED,
      POSTCODE_SERVICE_EVENTS.SET_POSTCODE,
      TOOLTIP_EVENTS.TOOLTIP_OPENED,
      PRODUCT_PAGE_EVENTS.FALLBACK_TO_MAKING_ALL_ON_DEMAND_SIZES_AVAILABLE,
      MainWebsiteEvents.CleanUpStorageKey,
      ...Object.values(BAG_PAGE_EVENTS),
      ...Object.values(PRODUCT_CALENDAR_SERVICE_EVENTS),
      ...Object.values(MyAccountCancelMyOrderEvents),
      ...Object.values(MyAccountOrderDetailsEvents),
      ...Object.values(MyAccountRefundMyOrderEvents),
      ...Object.values(SubscriptionEvents),
      ...Object.values(OnDemandEvents),
      ...Object.values(CheckoutEvents),
    ];
    const excludedBusEvents = [
      SubscriptionEvents.AddToSubscriptionBox,
      SubscriptionEvents.BoxUpdated,
      SubscriptionEvents.BoxItemAdded,
      SubscriptionEvents.BoxItemRemoved,
      SubscriptionEvents.BoxRemoveItem,
      SubscriptionEvents.BoxUpdated,
      SubscriptionEvents.SignUpPaymentSubmitted,
      SubscriptionEvents.SubscriptionPlaceOrderSuccess,
      SubscriptionEvents.SubscriptionPlaceOrderError,
      SubscriptionEvents.SignUpPlanChange,
      SubscriptionEvents.SignUpPlanChosen,
      SubscriptionEvents.SignUpCreateAccountPageLoaded,
      SubscriptionEvents.SignUpLoginPageLoaded,
      SubscriptionEvents.BoxConfirmed,
      "checkout",
      OnDemandEvents.AddToCart,
      OnDemandEvents.CartItemAdded,
      OnDemandEvents.CartItemRemoved,
      OnDemandEvents.CartRemoveItem,
      OnDemandEvents.CartUpdated,
      OnDemandEvents.CartUpdateItem,
      OnDemandEvents.CartUpdateProducts,
      OnDemandEvents.CheckoutPlaceOrderSuccess,
      OnDemandEvents.CheckoutAuthToLeave,
      OnDemandEvents.CartProceedToCheckout,
      OnDemandEvents.CheckoutBillToSameAddress,
      OnDemandEvents.CheckoutClickedUseCouponCode,
      OnDemandEvents.CheckoutCouponCodeApplied,
      OnDemandEvents.CheckoutPageLoaded,
      OnDemandEvents.CheckoutNumberOfItemsInTheBag,
      OnDemandEvents.CheckoutPageLoadedAsGuest,
      OnDemandEvents.CheckoutPageLoadedLoggedIn,
      OnDemandEvents.CheckoutPaymentMethodSelected,
      OnDemandEvents.CheckoutPlaceOrderInitialized,
      OnDemandEvents.CheckoutPlaceOrderSuccess,
      OnDemandEvents.CheckoutProceededToPayment,
      OnDemandEvents.CheckoutTermsAndConditionsChecked,
      OnDemandEvents.CleanUpStorageKey,
      OnDemandEvents.DisableBackupDress,
      OnDemandEvents.EnableBackupDress,
      OnDemandEvents.UpdatedCartItem,
    ];
    const filteredEvents = busEvents.filter((event) => !excludedBusEvents.includes(event));
    forwardBusEventsToAnalytics(filteredEvents);

    Array.from(document.querySelectorAll(".js-items-carousel-add-to-bag")).forEach((item) => {
      item.addEventListener("click", () => {
        pushEvent("bag-page-add-from-complete-your-look", null);
      });
    });
  })(GlamCornerCore.bus);

  // Log snapshot of dataLayer for debugging GTM
  //
  // Only log datalayer on checkout for now, as we're not sure how much data will be generated
  // or how useful it will be.
  if ("sendBeacon" in window.navigator && window.location.pathname.indexOf("/checkout") === 0) {
    let gcsession;
    GlamCornerCore.bus.on("gcsession-xhr-finished", function (data) {
      gcsession = data;
      console.log("Logging pageview");
      const pageViewLog = {
        type: "LogClientPageView",
        path: window.location.pathname,
        gcsession: gcsession,
      };
      window.navigator.sendBeacon("/log", JSON.stringify(pageViewLog));
    });

    const logSnapshot = function (logTrigger) {
      console.log("Logging dataLayer");
      const dataLayerLog = {
        type: "LogClientDataLayerSnapshot",
        dataLayer: window.dataLayer,
        path: window.location.pathname,
        gcsession: gcsession,
        logTrigger: logTrigger,
      };
      window.navigator.sendBeacon("/log", JSON.stringify(dataLayerLog));
    };

    let logTimeout = setTimeout(() => {
      logSnapshot("timer");
    }, 60 * 1000);

    // We want to log the datalayer snapshot as late as possible, to allow time for all events to be pushed to
    // the datalayer before we record it. We do this by only logging on page unload, and using sendBeacon which
    // will reliably deliver even when page is unloading. It wont work on IE, but this is ok, as we're only
    // using this for debugging GTM issues at the moment.
    window.addEventListener("unload", () => {
      clearTimeout(logTimeout);
      logSnapshot("unload");
    });
  }
});

export default Analytics;
