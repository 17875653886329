import Bus from "./Bus";
import {
  AccountsEvents,
  CatalogueEvents,
  CatalogueEventsData,
  OnDemandEvents,
  OnDemandEventsData,
  StylePageEvents,
  SubscriptionEvents,
} from "@glamcorner/gc-types/generated/ipcBusEvents";
import { Style } from "@glamcorner/gc-types/generated/types";
import { POSTCODE_SERVICE_EVENTS } from "./constants";
import CheckoutSetIdentifyData = OnDemandEventsData.CheckoutSetIdentifyData;
import { AnalyticsLog, analyticsLog as analyticsLogFactoryImpl, AnalyticsLogFactory } from "./analyticsLog";

export interface GtmEvent {
  [key: string]: any;
}

type BoxItem = Style & {
  product_code: string;
  size: string;
  isAvailable: boolean;
};

type AnalyticsEvent<D> = { eventName: string; transform: (data: D) => GtmEvent[] };

const analyticsEvents: AnalyticsEvent<any>[] = [
  {
    eventName: SubscriptionEvents.AddToSubscriptionBox,
    transform: (product: { [key: string]: any }) => {
      const events: GtmEvent[] = [
        {
          event: SubscriptionEvents.AddToSubscriptionBox,
          ecommerce: {
            add: {
              products: [product],
            },
          },
        },
        {
          event: "Product Added",
          ecommerce: {
            add: {
              actionField: {
                affiliation: "subscription",
              },
              products: [
                {
                  name: product.name,
                  id: product.id,
                  price: 0,
                  brand: product.brand,
                  category: "",
                  variant: "rental",
                  coupon: undefined,
                  quantity: 1,
                  dimension7: product.size,
                  metric1: undefined,
                  metric2: undefined,
                },
              ],
            },
          },
        },
      ];
      return events;
    },
  },
  {
    eventName: CatalogueEvents.UsedLegacyFilterUrl,
    transform: (data: { [key: string]: any }) => [
      {
        event: CatalogueEvents.UsedLegacyFilterUrl,
        ...data,
      },
    ],
  },
  {
    eventName: CatalogueEvents.LegacyMagentoCatalogueLoaded,
    transform: (data: { [key: string]: any }): GtmEvent[] => [
      {
        event: CatalogueEvents.LegacyMagentoCatalogueLoaded,
        ...data,
      },
    ],
  },
  {
    eventName: OnDemandEvents.CheckoutPlaceOrderSuccess,
    transform: (data: OnDemandEventsData.CheckoutPlaceOrderSuccessData) => {
      const products = data.products.map((product) => {
        return {
          name: product.name,
          id: product.style,
          price: product.price,
          brand: product.brand,
          category: product.category,
          coupon: product.isBackup ? "backup" : undefined,
          variant: product.isClearanceItem ? "clearance" : product.is_rental ? "on-demand" : "purchase",
          quantity: product.quantity,
          dimension7: product.size,
          metric1: product.rentalDaysInAdvance,
          metric2: product.rentalDatesRange,
        };
      });
      const events: GtmEvent[] = [
        {
          event: "checkout",
          ecommerce: {
            purchase: {
              actionField: {
                id: data.id,
                affiliation: "Online Store",
                revenue: data.value,
                coupon: data.coupon_code,
              },
              products: data.products,
            },
          },
        },
        {
          event: "Order Completed",
          ecommerce: {
            purchase: {
              actionField: {
                id: data.id,
                affiliation: products[0].variant,
                revenue: data.value,
                tax: data.tax,
                shipping: data.shipping,
                coupon: data.coupon_code,
              },
              products,
            },
          },
          paymentMethod: data.paymentMethod,
        },
      ];

      return events;
    },
  },
  {
    eventName: POSTCODE_SERVICE_EVENTS.SET_POSTCODE,
    transform: (data: { postcode: string }) => [
      {
        event: "Identify",
        postcode: data.postcode || undefined,
      },
    ],
  },
  {
    eventName: OnDemandEvents.CheckoutSetIdentify,
    transform: (data: CheckoutSetIdentifyData) => [
      {
        event: "Identify",
        postcode: data.postCode || undefined,
        email: data.email || undefined,
      },
    ],
  },
  {
    eventName: AccountsEvents.CustomerLogin,
    transform: (data: { [key: string]: any }) => [
      {
        event: "Identify",
        ...data,
      },
    ],
  },
  {
    eventName: AccountsEvents.CustomerNotLoggedIn,
    transform: (data: { [key: string]: any }) => [
      {
        event: "Identify",
        ...data,
      },
    ],
  },
  {
    eventName: SubscriptionEvents.SignUpPaymentSubmitted,
    transform: (data: { [key: string]: any }) => [
      {
        event: "Identify",
        userId: data.customerId,
        subscriptionType: data.subscriptionType,
      },
      {
        event: "Subscription Started",
        subscriptionId: data.subscriptionId,
        subscriptionType: data.subscriptionType,
        subscriptionValue: data.planAmount,
        subscriptionLifecycle: data.subscriptionLifecycle,
        subscriptionCoupon: data.couponCode || undefined,
      },
    ],
  },
  {
    eventName: OnDemandEvents.CheckoutPageLoaded,
    transform: (data: OnDemandEventsData.CheckoutPageLoadedData) => {
      const products = data.products.map((product) => {
        return {
          name: product.name,
          id: product.style,
          price: product.price,
          brand: product.brand,
          category: product.category,
          coupon: product.isBackup ? "backup" : undefined,
          variant: product.isClearanceItem ? "clearance" : product.is_rental ? "on-demand" : "purchase",
          quantity: product.quantity,
          dimension7: product.size,
          metric1: product.rentalDaysInAdvance,
          metric2: product.rentalDatesRange,
        };
      });
      return [
        {
          event: "Checkout Started",
          ecommerce: {
            checkout: {
              actionField: {
                affiliation: products[0].variant,
                revenue: data.value,
                tax: data.tax,
                shipping: data.shipping,
              },
              products,
            },
          },
        },
      ];
    },
  },
  {
    eventName: SubscriptionEvents.SubscriptionPlaceOrderSuccess,
    transform: (data: { [key: string]: any }) => [
      {
        event: "Order Completed",
        ecommerce: {
          purchase: {
            actionField: {
              id: data.orderId,
              affiliation: "subscription",
              revenue: 0,
              tax: 0,
              shipping: 0,
              coupon: undefined,
            },
            products: data.boxItems.map((product: BoxItem) => {
              return {
                name: product.name,
                id: product.style_code,
                price: 0,
                brand: product.designer,
                category: undefined,
                coupon: undefined,
                variant: "subscription",
                quantity: 1,
                dimension7: product.size,
                metric1: undefined,
                metric2: undefined,
              };
            }),
          },
        },
        paymentMethod: data.paymentMethod,
      },
    ],
  },
  {
    eventName: OnDemandEvents.CartItemAdded,
    transform: (data: { [key: string]: any }) => [
      {
        event: "Product Added",
        ecommerce: {
          add: {
            actionField: {
              affiliation: data.variant,
            },
            products: [
              {
                name: data.name,
                id: data.styleCode,
                price: data.price,
                brand: data.designer,
                category: "",
                variant: data.variant,
                coupon: data.isBackup || undefined,
                quantity: 1,
                dimension7: data.size,
                metric1: data.rentalDaysInAdvance,
                metric2: data.rentalDatesRange,
              },
            ],
          },
        },
      },
    ],
  },
  {
    eventName: OnDemandEvents.CartItemRemoved,
    transform: (data: { [key: string]: any }) => [
      {
        event: "Product Removed",
        ecommerce: {
          remove: {
            actionField: {
              affiliation: data.variant,
            },
            products: [
              {
                name: data.name,
                id: data.styleCode,
                price: data.price,
                brand: data.designer,
                category: "",
                variant: data.variant,
                coupon: data.isBackup || undefined,
                quantity: 1,
                dimension7: data.size,
                metric1: data.rentalDaysInAdvance,
                metric2: data.rentalDatesRange,
              },
            ],
          },
        },
      },
    ],
  },
  {
    eventName: SubscriptionEvents.BoxRemoveItem,
    transform: (data: BoxItem) => [
      {
        event: "Product Removed",
        ecommerce: {
          remove: {
            actionField: {
              affiliation: "subscription",
            },
            products: [
              {
                name: data.name,
                id: data.style_code,
                price: 0,
                brand: data.designer,
                category: "",
                variant: "subscription",
                coupon: undefined,
                quantity: 1,
                dimension7: data.size,
                metric1: undefined,
                metric2: undefined,
              },
            ],
          },
        },
      },
    ],
  },
  {
    eventName: StylePageEvents.PageFinishedLoading,
    transform: (data: { [key: string]: any }) => [
      {
        event: "Product Viewed",
        ecommerce: {
          detail: {
            actionField: {
              affiliation: data.variant,
            },
            products: [
              {
                name: data.name,
                id: data.style,
                price: data.price,
                brand: data.brand,
                category: "",
                variant: data.variant,
                dimension7: data.size,
                metric1: data.rentalDaysInAdvance,
                metric2: data.rentalDatesRange,
              },
            ],
          },
        },
      },
    ],
  },
  {
    eventName: CatalogueEvents.StyleItemClicked,
    transform: (data: CatalogueEventsData.StyleItemAnalyticsData) => [
      {
        event: "Product Clicked",
        ecommerce: {
          click: {
            actionField: {
              affiliation: data.variant,
            },
            products: [
              {
                name: data.name,
                id: data.id,
                price: data.price,
                brand: data.brand,
                category: "",
                variant: data.variant,
                dimension7: data.size,
                metric1: data.rentalDaysInAdvance,
                metric2: data.rentalDatesRange,
              },
            ],
          },
        },
      },
    ],
  },
  {
    eventName: CatalogueEvents.StyleItemsViewed,
    transform: (data: CatalogueEventsData.CatalogueImpressionData) => {
      const products = data.products.map((product) => {
        return {
          name: product.name,
          id: product.id,
          price: product.price,
          brand: product.brand,
          category: product.category,
          variant: product.variant,
          dimension7: product.size,
          metric1: product.rentalDaysInAdvance,
          metric2: product.rentalDatesRange,
          position: product.position,
          list: product.list,
        };
      });
      return [
        {
          event: "Product List Viewed",
          ecommerce: {
            impressions: {
              actionField: {
                affiliation: products[0].variant,
              },
              products,
            },
          },
          filters: data.filters,
        },
      ];
    },
  },
  {
    eventName: CatalogueEvents.StyleItemsFiltered,
    transform: (data: CatalogueEventsData.CatalogueImpressionData) => {
      const products = data.products.map((product) => {
        return {
          name: product.name,
          id: product.id,
          price: product.price,
          brand: product.brand,
          category: product.category,
          variant: product.variant,
          dimension7: product.size,
          metric1: product.rentalDaysInAdvance,
          metric2: product.rentalDatesRange,
          position: product.position,
          list: product.list,
        };
      });
      return [
        {
          event: "Product List Filtered",
          ecommerce: {
            impressions: {
              actionField: {
                affiliation: products[0].variant,
              },
              products,
            },
          },
          filters: data.filters,
        },
      ];
    },
  },
  {
    eventName: CatalogueEvents.StyleItemsSearched,
    transform: (data: CatalogueEventsData.CatalogueSearchData) => [
      {
        event: "Products Searched",
        searchQuery: data.searchText,
      },
    ],
  },
];

export default (
  dataLayer: GtmEvent[],
  bus: Bus,
  analyticsLogFactory: AnalyticsLogFactory = analyticsLogFactoryImpl
) => {
  const analyticsLog: AnalyticsLog = analyticsLogFactory();
  analyticsEvents.forEach(({ eventName, transform }: AnalyticsEvent<any>) => {
    bus.on(eventName, {}, (data: any) => {
      const events = transform(data);
      dataLayer.push(...events);
      events.forEach((event) => analyticsLog(event));
    });
  });
};
