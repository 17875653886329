import { getOwnerFromStorefront, hostnameToStorefront } from "@glamcorner/core-cf-lib/lib/client";
import { GetTraceId, getTraceId as getTraceIdImpl } from "./analyticsLog/traceId";

const buildMessage = (event: object, getTraceId: GetTraceId) => {
  const storefront = hostnameToStorefront();
  const owner = getOwnerFromStorefront(storefront);
  const traceId = getTraceId();
  return {
    type: "LogClientAnalyticsEvent",
    event: event,
    storefront,
    owner,
    traceId,
  };
};

export type AnalyticsLog = (event: object) => void;
export type AnalyticsLogFactory = (sendBeacon?: NavigatorBeacon["sendBeacon"], getTraceId?: GetTraceId) => AnalyticsLog;

export const analyticsLog: AnalyticsLogFactory =
  (sendBeacon = window.navigator.sendBeacon, getTraceId = getTraceIdImpl) =>
  (event: object) => {
    const message = buildMessage(event, getTraceId);
    sendBeacon.bind(window.navigator)("/log", JSON.stringify(message));
  };
