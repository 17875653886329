export const TOOLTIP_EVENTS = {
  TOOLTIP_OPENED: "gc-tooltip-opened",
};

export const CATALOG_EVENTS = {
  FILTER_SELECTION: "filter-selection",
};

export const DATE_PICKER_EVENTS = {
  DATE_SELECTED: "calendar.dateSelected",
};

export const BAG_PAGE_EVENTS = {
  PRODUCTS_NO_LONGER_AVAILABLE: "bag-page--products-no-longer-available",
  REPLACE_PRODUCT_CLICKED: "bag-page--replace-product-clicked",
};

export const PRODUCT_CALENDAR_SERVICE_EVENTS = {
  FETCH_ON_DEMAND_AVAILABILITY_FAILURE: "product-calendar-service--fetch-on-demand-availabilities-failure",
  FETCH_ON_DEMAND_AVAILABILITY_SUCCESS: "product-calendar-service--fetch-on-demand-availabilities-success",
};

export const POSTCODE_SERVICE_EVENTS = {
  SET_POSTCODE: "set-postcode",
};

export const PRODUCT_PAGE_EVENTS = {
  FALLBACK_TO_MAKING_ALL_ON_DEMAND_SIZES_AVAILABLE: "product-page--fallback-to-making-all-on-demand-sizes-available",
  PRODUCT_PAGE_INITED: "product-page-inited",
  UNSELECTED_DIGITAL: "unselected-digital",
  DISABLED_SIZE: "attempted-to-select-disabled-size",
};

export const EMBED_APP_EVENTS = {
  EMBED_APP_LOADED: "embed-app-loaded",
};
