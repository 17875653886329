const COOKIE_NAME = "gc_analytics_trace_id";

const uniqueId = () => Date.now().toString(16) + Math.random().toString(16);

export const _testHelper = {
  setDocumentCookie: (s: string) => {
    document.cookie = s;
  },
};

const setCookie = (name: string, value: string) => {
  const parts = [`${encodeURIComponent(name)}=${encodeURIComponent(value)}`];
  parts.push("path=/");
  parts.push("expires=Fri, 01 Jan 2038 00:00:00 GMT");
  if (document.domain) {
    parts.push(`domain=.${document.domain}`);
  }
  console.log(parts.join("; "));
  _testHelper.setDocumentCookie(parts.join("; "));
};

const getCookie = (name: string) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    var c = cookies[i].trim();
    if (c.indexOf(`${name}=`) == 0) {
      return c.substring(name.length + 1, c.length);
    }
  }
  return null;
};

export type GetTraceId = () => string;

export const getTraceId = () => {
  let traceId = getCookie(COOKIE_NAME);
  if (!traceId) {
    traceId = uniqueId();
    setCookie(COOKIE_NAME, traceId);
  }
  return traceId;
};
