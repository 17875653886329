import { MainWebsiteEvents, AccountsEvents } from "@glamcorner/gc-types/generated/ipcBusEvents";
import { GcSessionInfo } from "@glamcorner/gc-types/generated/types";
import { isSubscriptionCustomer, getSubscriptionType } from "../core/GcSession";

export default function (windowLocation: Location = window.location) {
  GlamCornerCore.bus.on(MainWebsiteEvents.GcSessionXhrFinished, {}, (session: GcSessionInfo) => {
    // always send one of AccountsEvents.CustomerLogin or AccountsEvents.CustomerNotLoggedIn
    const sendCustomerNotLoggedIn = ({
      customerId,
      firstName,
      lastName,
      email,
      postcode,
    }: {
      customerId?: string;
      firstName?: string;
      lastName?: string;
      email?: string;
      postcode?: string;
    }) => {
      GlamCornerCore.bus.broadcast(AccountsEvents.CustomerNotLoggedIn, {
        userId: customerId,
        firstName,
        lastName,
        email,
        postcode,
      });
    };

    if (session.customerInfo) {
      const { customerId, firstName, lastName, email, shippingAddress } = session.customerInfo;
      const postcode = shippingAddress ? shippingAddress.postCode : session.postcode;

      if (session.loggedIn) {
        GlamCornerCore.bus.broadcast(AccountsEvents.CustomerLogin, {
          userId: customerId,
          firstName,
          lastName,
          email,
          subscriptionType: getSubscriptionType(session.customerInfo),
          postcode: postcode || undefined,
        });
      } else {
        sendCustomerNotLoggedIn(session.customerInfo);
      }

      if (session.justLoggedIn) {
        GC.analytics.customerLogin({
          customerId,
          firstName,
          lastName,
          email,
          isSubscriptionCustomer: isSubscriptionCustomer(session.customerInfo),
        });
      }
    } else {
      sendCustomerNotLoggedIn({});
    }
  });

  GlamCornerCore.bus.on(AccountsEvents.CustomerLogout, {}, () => {
    GC.analytics.customerLogout();

    setTimeout(() => {
      windowLocation.replace("/customer/account/logout");
    }, 200);
  });
}
